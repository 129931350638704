@import "../../global/mixins";

.menu-active {
  display: flex;
  color: #ffffff;
  font-weight: 600;
}

.menu-sections {
  margin-right: 10px;
  cursor: pointer;
  filter: drop-shadow(2px 2px 2px transparent);
    transition: all 0.5s;

    &:hover {
      filter: drop-shadow(2px 2px 2px #ffffff);
    }
}

.menu-sections::after {
  // display: block;
  content: "";
  border-bottom: 1px solid;
  border-image-slice: 1;
  border-image-source: linear-gradient(to right, #a178e8, #e1dbeb);
  transform: scaleX(0);
  transition: transform 250ms ease-in-out;
}

.menu-sections:hover:after {
  transform: scaleX(1);
}

@include breakpoint("medium-large"){
  .menu-visible {
    font-size: 18px;
  }
  .menu-sections {
    margin-right: 25px;
  }
}

@include breakpoint("burger-menu"){
  nav {
    padding: 20px;
  }

  .burger-menu {
    height: 30px;
    width: 30px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-evenly;
    cursor: pointer;
  }

  .burger-bar {
    width: 30px;
    height: 2px;
    background: linear-gradient(90deg, rgba(161,120,232,1) 0%, rgba(225,219,235,1) 100%);
    border-radius: 2px;
  }

  .menu {
    width: 100%;
    height: 100vh;
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    position: fixed;
    top: 0;
    right: 0;
    z-index: -1;
  }

  .hidden {
    z-index: -1;
    width: 1px;
    transition: all ease-in-out 0.3s;
    margin-top: 90px;
    position: absolute;
    top: 0;
    right: -1px;
  }

  .visible {
    z-index: 1;
    display: inherit;
    transition: all ease-in-out 0.5s;
    margin-top: 90px;
    position: absolute;
    top: 0;
    right: 0;
  }

  .menu-active {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    align-items: center;
    padding: 10px;
    height: 120px;
    margin-top: 120px;
    font-weight: 600;
    font-size: 18px;
    color: #ffffff;
    font-weight: 600;
  }
  .menu-none {
    display: none;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    align-items: center;
    padding: 10px;
    height: 120px;
    margin-top: 120px;
    font-weight: 600;
    font-size: 18px;
    color: #ffffff;
  }

  .menu-sections {
    width: 100%;
    padding: 20px 0px;
    text-align: center;
    letter-spacing: 0.2rem;
    font-size: 24px;
    display: inline-block;
  }

  .menu-sections::after {
    display: block;
    content: "";
    border: 1px solid;
    border-image-slice: 1;
    border-image-source: linear-gradient(to right, #a178e8, #e1dbeb);
    transform: scaleX(0);
    transition: transform 250ms ease-in-out;
  }

  .menu-sections:hover:after {
    transform: scaleX(1);
  }

  .burger-bar.clicked:nth-child(1) {
    transform: rotate(45deg) translate(0px, 4px);
    transition: ease-out 0.5s;
  }

  .burger-bar.clicked:nth-child(2) {
    transform: scale(0);
    transition: ease-out 0.5s;
  }

  .burger-bar.clicked:nth-child(3) {
    transform: rotate(135deg) translate(-7px, 12px);
    transition: ease-out 0.5s;
  }

  .burger-bar.unclicked {
    transform: rotate(0) translate(0);
    transition: cubic-bezier(0.175, 0.885, 0.32, 1.275) 0.5s;
  }
}
