@import "../../global/mixins";

.our-app {
  &__container {
    width: calc(100% - 40px);
    height: fit-content;
    padding: 20px;
    color: white;
    margin-bottom: 24px;

    &__background-img{
      position: absolute;
      opacity: 0.4;
      width: 200px;
      right: 0;
    }
  }
  &__card{
    width: calc(100% - 32px);
    height: 100%;
    backdrop-filter: blur(10px);
    border-radius: 20px;
    background-color: rgba(0, 0, 0, .10);
    padding: 16px;
    margin-top: 40px;
  }

  &__title{
    font-size: 24px;
    font-weight: bold;
  }

  &__content{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 0px 24px;

    &__text{
      display: flex;
      flex-direction: column;
    }

    &__title{
      text-align: center;
      font-weight: bold;
      margin-bottom: 15px;
    }

    &__first{
      display: flex;
      flex-direction: column;
      margin-top: 40px;
    }

    &__description{
      text-align: center;
    }

    &__icon{
      align-self: center;
      width: 72px;
      height: 72px;
      margin-bottom: 20px;
      filter: invert(1);
    }
  }
}

@include breakpoint("medium") {
  .our-app{
    &__container{
      width: calc(100% - 80px);
      padding: 40px;
    }
    &__title{
      font-size: 28px;
    }

    &__card{
      width: calc(100% - 80px);
      padding: 40px;
    }
    
    &__content{
      font-size: 20px;
      justify-content: space-around;

      &__icon{
        width: 80px;
        height: 80px;
      }
    }
  }
}

@include breakpoint("medium-large") {
  .our-app{
    &__container{
      // width: 80%;
      // margin: 0 auto;
      &__background-img{
        right: 15%;
      }
    }

    &__content{
      flex-direction: row;
      align-items: start;

      &__first{
        flex-direction: column;
        max-width: 300px;
      }

      &__icon{
        margin-bottom: 40px;
      }
    }
  }
}