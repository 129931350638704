@import "../../global/mixins";

.our-team {
  &__container {
    width: calc(100% - 40px);
    height: fit-content;
    margin: 20px;
    color: white;
    overflow-x: hidden;
  }

  &__title-container {
    width: 100%;
    border-bottom: 2px solid rgb(249, 248, 255, 0.2);
    padding-bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &__text {
      font-size: 24px;
      font-weight: bold;
    }

    &__arrow {
      height: 36px;
      width: 36px;
      filter: drop-shadow(2px 2px 2px transparent);
      transition: all 0.5s;

      &:hover {
        filter: drop-shadow(2px 2px 2px #ffffff);
      }
    }

    &__arrows {
      padding-top: 5px;
    }
  }

  &__members-container {
    display: flex;
    // overflow-y: hidden; // uncomment on slider created
    position: relative;
    width: 100%;
    transition: all ease-in-out .5s;
  }

  &__members {
    display: flex;
    flex-direction: column;
    margin-top: 35px;
    margin-right: 20px;

    &__img {
      width: 230px;
      height: 290px;
      border-radius: 12px;
    }

    &__name {
      margin-top: 15px;
      font-size: 18px;
      font-weight: bold;
      text-transform: uppercase;
    }

    &__description {
      font-size: 12px;
      text-transform: uppercase;
    }
  }
}

@include breakpoint("medium") {
  .our-team {
    &__container{
      width: calc(100% - 80px);
      margin: 40px;
    }
    &__title-container {
      &__text {
        font-size: 28px;
      }
      &__arrow {
        width: 40px;
        height: 40px;
      }
    }
    &__members {
      &__img {
        width: 300px;
        height: 400px;
        object-fit: cover;
        object-position: left;
      }

      &__name {
        font-size: 22px;
      }
      &__description {
        font-size: 14px;
      }
    }
  }
}
