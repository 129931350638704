@import "../../global/mixins";

.header {
  position: fixed;
  z-index: 10;
  width: 100%;
  
  &__container{
    height: 70px;
    width: calc(100% - 40px);
    padding: 12px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #ffffff;
    max-width: 1366px;
    margin: 0 auto;
    background: linear-gradient(90deg, rgba(32, 24, 89, 1) 0%, rgb(57, 0, 102) 100%);
    display: flex;
  }

  &__title {
    font-size: 24px;
    cursor: pointer;
  }
}

@include breakpoint("medium") {
  .header {
    font-size: 18px;
  }
}
