@import "../../global/mixins";

.footer {
  width: calc(100% - 40px);
  height: fit-content;
  padding: 20px;
  color: white;
  display: flex;
  justify-content: center;
  margin-top: 20px;

  &__container {
    display: flex;
    justify-content: center;
    margin-top: 40px;
    height: 50px;

    &__text {
      font-size: 12px;
      color: white;
      text-align: center;
    }
  }

  &__logos {
    filter: drop-shadow(2px 2px 2px transparent);
    transition: all 0.5s;

    &:hover {
      filter: drop-shadow(2px 2px 2px #ffffff);
    }

    &__container {
      width: 50%;
      display: flex;
      justify-content: space-between;
    }
  }
}

.line-svg {
  width: 100%;
}

@include breakpoint("medium") {
  .footer {
    &__container {
      &__text {
        font-size: 18px;
      }
    }
    &__logos {
      &__container {
        justify-content: space-evenly;
      }
    }
  }
}
