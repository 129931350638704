@import url('https://fonts.googleapis.com/css2?family=Geologica:wght@100;200;300;400;500;600;700&family=Mulish:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;0,1000;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900;1,1000&family=Sora:wght@100;200;300;400;500;600;700;800&display=swap');

html,
body,
#root {
  height: 100%;
  width: 100%;
  font-family: "Sora", sans-serif;
  background: linear-gradient(90deg, rgba(32, 24, 89, 1) 0%, rgb(57, 0, 102) 100%);
}

.container__principal {
  height: calc(100% - 70px);
  margin: 0 auto;
  max-width: 1366px;
  padding-top: 94px;
}
