@import "../../global/mixins";

.main-container {
  width: calc(100% - 40px);
  display: flex;
  flex-direction: column;
  padding: 12px 20px;
  justify-content: space-around;

  &__content {
    display: flex;
    flex-direction: column;

  }

  &__title {
    color: white;
    font-size: 48px;
    font-weight: bold;
  }

  &__text {
    margin-top: 20px;
    color: whitesmoke;
    font-size: 18px;

    br{
      display: none;
    }
  }
}

.download-container {
  display: flex;
  align-items: center;
  flex-direction: column;

  &__img {
    margin-top: 20px;
    width: 100%;
    // height: 200px;
  }

  &__img-container {
    display: flex;
    align-items: center;
    margin-top: 25px;

    &__desktop{
      display: none;
    }
  }

  &__img-google {
    width: 170px;
    height: 70px;
  }
  &__img-apple {
    width: 140px;
    height: 48px;
  }
}

@include breakpoint("medium") {
  .main-container {
    // height: calc(100vh - 94px);
    justify-content: normal;
    width: calc(100% - 80px);
    padding: 20px 40px;

    &__title {
      font-size: 60px;
    }
  
    &__text {
      font-size: 24px;
    }

  }
}

@include breakpoint("medium-large") {
  .main-container{
    display: flex;
    flex-direction: row;
    height: calc(100vh - 94px);
    width: calc(100% - 160px);
    padding: 0px 80px;

    &__text{
      width: 310px;
      font-size: 20px;

      br{
        display: block;
      }
    }

    &__content{
      width: 50%;
      padding-right: 12px;
      justify-content: center;
    }
  }

  .download-container{
    justify-content: center;

    &__img{
      width: 100%;
    }
    &__img-container {
      display: none;
      &__desktop{
        display: flex;
        align-items: center;
        margin-top: 20px;
      }
    }
  }
}

@include breakpoint("large") {
  .main-container__text{
    width: 410px;
    font-size: 24px;
  }
}